<script lang="ts" context="module">
  export const matchingProps = [];
</script>

<script lang="ts">
  import { writable } from 'svelte/store';
  import AdminGridWithDetail from '../admin/AdminGridWithDetail.svelte';
  import TabControl from '../elements/TabControl.svelte';
  import AdminSubsetChooser from '../admin/AdminSubsetChooser.svelte';
  import { CustomGridColumn } from 'dbgate-datalib';
  import { adminConnectionColumns, adminRoleColumns, adminUserColumns } from '../admin/adminGridColumns';
  import PermissionsChooser from '../admin/PermissionsChooser.svelte';
  import { apiCall } from '../utility/api';
  import { getPredefinedPermissions } from 'dbgate-tools';
  import ErrorInfo from '../elements/ErrorInfo.svelte';
  import hasPermission from '../utility/hasPermission';
  import { mountStorageConnectionPing } from '../utility/connectionsPinger';

  export let tabid;

  mountStorageConnectionPing();

  const selectedObjectStore = writable({});

  let basePermissions = [];

  async function loadBasePermissions(userId) {
    const permissions = await apiCall('storage/read-user-role-permissions', {
      userId,
    });
    basePermissions = [...getPredefinedPermissions('logged-user'), ...permissions];
  }

  $: loadBasePermissions($selectedObjectStore?.['id']);
</script>

{#if hasPermission('admin/users')}
  <AdminGridWithDetail
    {tabid}
    fireEvents={['admin-users-changed']}
    pureAdminTableName="users"
    columns={adminUserColumns}
    {...$$restProps}
    {selectedObjectStore}
    icon="icon users"
    newButtonTitle="New user"
    getNewObject={() => ({})}
  >
    <svelte:fragment slot="detail" let:changeSetState let:dispatchChangeSet let:saveKey>
      <TabControl
        tabs={[
          { label: 'Connections', slot: 1 },
          { label: 'Roles', slot: 2 },
          { label: 'Permissions', slot: 3 },
        ]}
      >
        <svelte:fragment slot="1">
          <div>
            <AdminSubsetChooser
              mainTableName="connections"
              mappingTableName="user_connections"
              columns={adminConnectionColumns}
              primaryValue={$selectedObjectStore?.['id']}
              primaryColumn="user_id"
              referencedColumn="connection_id"
              {changeSetState}
              {dispatchChangeSet}
              reloadOnEvent="admin-connections-changed"
            />
          </div>
        </svelte:fragment>
        <svelte:fragment slot="2">
          <div>
            <AdminSubsetChooser
              mainTableName="roles"
              mappingTableName="user_roles"
              primaryValue={$selectedObjectStore?.['id']}
              primaryColumn="user_id"
              referencedColumn="role_id"
              {changeSetState}
              {dispatchChangeSet}
              columns={adminRoleColumns}
              reloadOnEvent="admin-roles-changed"
            />
          </div>
        </svelte:fragment>
        <svelte:fragment slot="3">
          <PermissionsChooser
            permissionsTableName="user_permissions"
            filterValue={$selectedObjectStore?.['id']}
            filterColumn="user_id"
            {changeSetState}
            {dispatchChangeSet}
            {saveKey}
            {basePermissions}
          />
        </svelte:fragment>
      </TabControl>
    </svelte:fragment>
  </AdminGridWithDetail>
{:else}
  <ErrorInfo message="You don't have permission to manage users" icon="img warn" alignTop />
{/if}
