<script>
  import SvelteMarkdown from 'svelte-markdown';
  import AiResultButton from './AiResultButton.svelte';
  import { setContext } from 'svelte';

  export let source;
  export let onButtonClick;

  setContext('onAiResultButtonClick', handleResultClick);

  function handleResultClick(value) {
    onButtonClick?.(value);
  }
</script>

<SvelteMarkdown {source} renderers={{ link: AiResultButton }} />
