<script lang="ts">
  import localforage from 'localforage';
  import FormStyledButton from '../buttons/FormStyledButton.svelte';
  import AiResultMarkdown from '../elements/AiResultMarkdown.svelte';
  import LoadingInfo from '../elements/LoadingInfo.svelte';
  import ConfirmModal from '../modals/ConfirmModal.svelte';
  import { showModal } from '../modals/modalTools';
  import { allowedSendToAiService } from '../stores';
  import { apiCall } from '../utility/api';
  import { showSnackbarError } from '../utility/snackbar';
  import WidgetColumnBar from '../widgets/WidgetColumnBar.svelte';
  import WidgetColumnBarItem from '../widgets/WidgetColumnBarItem.svelte';
  import { onMount } from 'svelte';
  import TextField from '../forms/TextField.svelte';
  import keycodes from '../utility/keycodes';

  export let onClose;
  export let conid;
  export let database;
  export let driver;
  export let text;
  export let getLine = null;
  export let getTextOrSelectedText = null;
  export let onInsertAtCursor = null;
  export let onSetSelectedText = null;
  export let tabid = null;

  let promptValue;
  let refactorValue;
  let isQueryRunning = false;

  let responseSql = null;
  let responseVariants = null;

  let taskType = 'textToSql';

  function clearResult() {
    responseSql = null;
    responseVariants = null;
  }

  async function initialLoad() {
    const state: any = await localforage.getItem(`tabdata_aiAssistantWidget_${tabid}`);
    if (state) {
      promptValue = state.question;
      refactorValue = state.task;
      taskType = state.taskType || 'textToSql';
      responseSql = state.responseSql;
      responseVariants = state.responseVariants;
    }
  }

  $: localforage.setItem(`tabdata_aiAssistantWidget_${tabid}`, {
    question: promptValue,
    task: refactorValue,
    taskType,
    responseSql,
    responseVariants,
  });

  async function handleTextToSql() {
    try {
      isQueryRunning = true;
      clearResult();
      const resp = await apiCall('database-connections/text-to-sql', {
        conid,
        database,
        text: promptValue,
        dialect: driver?.title,
      });
      if (resp.errorMessage) {
        showSnackbarError('API error:' + resp?.errorMessage);
        return;
      }
      responseSql = resp.sql;
    } finally {
      isQueryRunning = false;
    }
  }

  export async function handleCompleteOnCursor() {
    try {
      isQueryRunning = true;
      clearResult();
      const resp = await apiCall('database-connections/complete-on-cursor', {
        conid,
        database,
        text,
        line: getLine(),
        dialect: driver?.title,
      });
      if (resp.errorMessage) {
        showSnackbarError('API error:' + resp?.errorMessage);
        return;
      }
      responseVariants = resp.variants;
    } finally {
      isQueryRunning = false;
    }
  }

  export function insertCompletion(index) {
    onInsertAtCursor(responseVariants[index]);
  }

  async function handleRefactorSql() {
    try {
      isQueryRunning = true;
      clearResult();
      const resp = await apiCall('database-connections/refactor-sql-query', {
        conid,
        database,
        task: refactorValue,
        query: getTextOrSelectedText(),
        dialect: driver?.title,
      });
      if (resp.errorMessage) {
        showSnackbarError('API error:' + resp?.errorMessage);
        return;
      }
      responseSql = resp.sql;
    } finally {
      isQueryRunning = false;
    }
  }

  function generateResultMarkdown(sql, variants) {
    if (sql) {
      return '```sql' + '\n' + sql + '\n```\n[Use this](sql)\n';
    }

    if (variants) {
      return variants
        ?.map((v, index) => '```sql\n' + v + '\n```\n[Use this](var:' + index + ') _Alt+' + (index + 1) + '_\n')
        .join('');
      // return variants?.map(v => v + '\n\n[Use]()\n\n').join('');
    }

    return null;
  }

  function handleResultButtonClick(href) {
    if (href?.startsWith('var:')) {
      onInsertAtCursor(responseVariants[href.substring(4)]);
    }
    if (href == 'sql') {
      if (taskType == 'textToSql') {
        onInsertAtCursor(responseSql);
      }
      if (taskType == 'refactorSql') {
        onSetSelectedText(responseSql);
      }
    }
  }

  $: resultMarkdown = generateResultMarkdown(responseSql, responseVariants);

  function handleAllowSendToAiService() {
    showModal(ConfirmModal, {
      message:
        'Really allow sending to AI service? This allows DbGate to send table and column names and descriptions, and SQL snippets to AI service. You could change your decision later in Settings.',
      onConfirm: () => {
        allowedSendToAiService.set(true);
      },
    });
  }

  onMount(initialLoad);
</script>

<WidgetColumnBar>
  <WidgetColumnBarItem title="AI Assistant" name="aiAssistant" storageName="aiAssistantWidget" {onClose}>
    <div class="wrapper">
      {#if $allowedSendToAiService}
        <div class="m-3">
          <div class="bold">Choose your task:</div>
          <div>
            <input type="radio" bind:group={taskType} value="textToSql" id="__textToSql" />
            <label for="__textToSql">Text to SQL</label>
          </div>
          <div>
            <input type="radio" bind:group={taskType} value="refactorSql" id="__refactorSql" />
            <label for="__refactorSql">Make changes on edited SQL</label>
          </div>
          <div>
            <input type="radio" bind:group={taskType} value="completeOnCursor" id="__completeOnCursor" />
            <label for="__completeOnCursor">Complete SQL on cursor</label>
          </div>
          <div class="ml-5 keystroke">Ctrl+Shift+Space</div>
        </div>

        {#if taskType == 'textToSql'}
          <div class="m-3 mb-0">Enter your question:</div>
          <TextField
            class="mx-3 my-1"
            bind:value={promptValue}
            data-testid="QueryAiAssistant_promptInput"
            focused
            on:keydown={e => {
              if (e.keyCode == keycodes.enter) handleTextToSql();
            }}
          />
          <div class="ml-3">
            <FormStyledButton
              value="Query from your question"
              on:click={handleTextToSql}
              skipWidth
              data-testid="QueryAiAssistant_queryFromQuestionButton"
            />
          </div>
        {/if}
        {#if taskType == 'completeOnCursor'}
          <div class="ml-3">
            <FormStyledButton
              value="Complete query on cursor"
              on:click={handleCompleteOnCursor}
              skipWidth
              disabled={text?.length > 10000}
              data-testid="QueryAiAssistant_completeQueryOnCursorButton"
            />
          </div>
        {/if}
        {#if taskType == 'refactorSql'}
          <div class="m-3 mb-0">Enter your command:</div>
          <TextField
            class="mx-3 my-1"
            bind:value={refactorValue}
            data-testid="QueryAiAssistant_refactorInput"
            focused
            on:keydown={e => {
              if (e.keyCode == keycodes.enter) handleRefactorSql();
            }}
          />
          <div class="ml-3">
            <FormStyledButton
              value="Refactor SQL query"
              on:click={handleRefactorSql}
              skipWidth
              data-testid="QueryAiAssistant_refactorButton"
            />
          </div>
        {/if}

        {#if isQueryRunning}
          <LoadingInfo message="Processing AI request..." />
        {:else if resultMarkdown}
          <div class="mdwrap ml-3 mt-3">
            <AiResultMarkdown source={resultMarkdown} onButtonClick={handleResultButtonClick} />
          </div>
        {/if}
      {:else}
        <div class="m-3">
          You have not allowed to send data to AI service. This is neccessary for AI Assistant to work.
        </div>
        <div class="m-3">
          Only some properties of your DB model is sent to AI service (eg. table names and descriptions, column names
          and descriptions, etc.). DbGate never sends content of your table data anywhere.
        </div>
        <div class="m-3">
          <FormStyledButton
            value="Allow send data to AI service"
            on:click={handleAllowSendToAiService}
            skipWidth
            data-testid="QueryAiAssistant_allowSendToAiServiceButton"
          />
        </div>
      {/if}
    </div>
  </WidgetColumnBarItem>
</WidgetColumnBar>

<style>
  .wrapper :global(input[type='text']) {
    min-width: 10px;
    min-height: 22px;
    border: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .mdwrap {
    position: relative;
    flex: 1;
    overflow: scroll;
  }

  .keystroke {
    font-style: italic;
  }
</style>
