<script lang="ts">
  import { getContext } from 'svelte';

  const onAiResultButtonClick = getContext('onAiResultButtonClick');

  export let href;

  function handleClick() {
    // @ts-ignore
    onAiResultButtonClick(href);
  }
</script>

<a on:click={handleClick}><slot /></a>

<style>
  a {
    border: 1px solid var(--theme-bg-button-inv-2);
    padding: 5px;
    margin: 2px;
    background-color: var(--theme-bg-button-inv);
    color: var(--theme-font-inv-1);
    border-radius: 2px;
  }

  a:hover {
    background-color: var(--theme-bg-button-inv-2);
  }
  a:active {
    background-color: var(--theme-bg-button-inv-3);
  }
</style>
