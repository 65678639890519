<script>
  import { writable } from 'svelte/store';

  import FormFieldTemplateLarge from '../forms/FormFieldTemplateLarge.svelte';
  import FormProviderCore from '../forms/FormProviderCore.svelte';
  import ErrorInfo from '../elements/ErrorInfo.svelte';
  import FormTextField from '../forms/FormTextField.svelte';
  import FormPasswordField from '../forms/FormPasswordField.svelte';
  import FormCheckboxField from '../forms/FormCheckboxField.svelte';
  import stableStringify from 'json-stable-stringify';
  import FormStyledButton from '../buttons/FormStyledButton.svelte';
  import CollapseButton from '../datagrid/CollapseButton.svelte';
  import { getAuthTypeLabel } from './authAdminTools';
  import { showModal } from '../modals/modalTools';
  import InputTextModal from '../modals/InputTextModal.svelte';

  export let authMethod;
  export let onChange;
  export let onDelete;

  const values = writable({ ...authMethod });

  $: {
    if (stableStringify($values) != stableStringify(authMethod)) {
      onChange($values);
    }
  }

  $: authType = $values?.type;
</script>

<FormProviderCore {values} template={FormFieldTemplateLarge}>
  <div class="header">
    <div class="title-line">
      <div class="name-container">
        <CollapseButton
          vertical
          collapsed={$values.isCollapsed}
          data-testid={`AdminAuthForm_collapseButton_${values.name || $values.type}`}
          on:click={() => {
            $values.isCollapsed = !$values.isCollapsed;
          }}
        />
        <div class="name-type">
          <div class="name" class:disabled={$values.isDisabled}>
            {$values.name}
          </div>
          {#if !$values.isDisabled && getAuthTypeLabel($values.type) != $values.name}
            <div class="type">{getAuthTypeLabel($values.type)}</div>
          {/if}
          {#if $values.isDisabled}
            <div class="type">(disabled)</div>
          {/if}
        </div>
      </div>
      <div>
        {#if $values.isDisabled}
          <FormStyledButton
            value="Enable"
            on:click={() => {
              $values.isDisabled = false;
            }}
            data-testid={`AdminAuthForm_enableButton_${values.name || $values.type}`}
          />
        {:else}
          <FormStyledButton
            value="Disable"
            on:click={() => {
              $values.isDisabled = true;
            }}
            data-testid={`AdminAuthForm_disableButton_${values.name || $values.type}`}
          />
        {/if}
        <FormStyledButton
          value="Delete"
          disabled={$values.id < 0}
          on:click={() => onDelete($values.amoid)}
          data-testid={`AdminAuthForm_deleteButton_${values.name || $values.type}`}
        />
        <FormStyledButton
          value="Rename"
          on:click={() => {
            showModal(InputTextModal, {
              value: $values.name,
              label: 'New authentication name',
              header: 'Rename authentication method',
              onConfirm: newName => {
                $values.name = newName;
              },
            });
          }}
          data-testid={`AdminAuthForm_renameButton_${values.name || $values.type}`}
        />
      </div>
    </div>
  </div>
  {#if !$values.isCollapsed}
    <div class="collapse-wrapper">
      {#if (authType ?? 'none') == 'none'}
        <div class="info-wrap">
          <ErrorInfo message="Your DbGate server will be available without authentication" icon="img info" alignTop />
        </div>
      {/if}

      {#if authType == 'local'}
        <div class="info-wrap">
          <ErrorInfo message="Define logins and passwords in Users administration" icon="img info" alignTop />
        </div>
      {/if}

      {#if authType == 'database'}
        <div class="info-wrap">
          <ErrorInfo
            message="Forward credentials to database server. User has to choose database connection from all defined connections at first. If DB uses SSO (eg. Microsoft Entra ID), user is redirected to database login page. If you have connections with password stored in connection, user is logged in automatically after choosing them."
            icon="img info"
            alignTop
          />
        </div>
        <FormCheckboxField
          label="Allow only defined logins"
          name="dbloginOnlyDefinedLogins"
          data-testid={`AdminAuthForm_dbloginOnlyDefinedLogins_${values.name || $values.type}`}
        />
      {/if}

      {#if authType == 'ad'}
        <FormTextField
          label="Active Directory URL"
          name="adUrl"
          data-testid={`AdminAuthForm_adUrl_${values.name || $values.type}`}
        />
        <FormTextField
          label="Base DN"
          name="adBaseDN"
          data-testid={`AdminAuthForm_adBaseDN_${values.name || $values.type}`}
        />
        <FormTextField
          label="AD User name"
          name="adLogin"
          data-testid={`AdminAuthForm_adLogin_${values.name || $values.type}`}
        />
        <FormPasswordField
          label="AD Password"
          name="adPassword"
          data-testid={`AdminAuthForm_adPassword_${values.name || $values.type}`}
        />
        <FormCheckboxField
          label="Allow only defined logins"
          name="adOnlyDefinedLogins"
          data-testid={`AdminAuthForm_adOnlyDefinedLogins_${values.name || $values.type}`}
        />
      {/if}

      {#if authType == 'oauth'}
        <FormTextField
          label="OAuth auth endpoint"
          name="oauthAuth"
          data-testid={`AdminAuthForm_oauthAuth_${values.name || $values.type}`}
        />
        <FormTextField
          label="OAuth token endpoint"
          name="oauthToken"
          data-testid={`AdminAuthForm_oauthToken_${values.name || $values.type}`}
        />
        <FormTextField
          label="OAuth logout endpoint"
          name="oauthLogout"
          data-testid={`AdminAuthForm_oauthLogout_${values.name || $values.type}`}
        />
        <FormTextField
          label="OAuth scope"
          name="oauthScope"
          data-testid={`AdminAuthForm_oauthScope_${values.name || $values.type}`}
        />
        <FormTextField
          label="OAuth client"
          name="oauthClient"
          data-testid={`AdminAuthForm_oauthClient_${values.name || $values.type}`}
        />
        <FormTextField
          label="OAuth client secret"
          name="oauthClientSecret"
          data-testid={`AdminAuthForm_oauthClientSecret_${values.name || $values.type}`}
        />
        <FormTextField
          label="OAuth login field"
          name="oauthLoginField"
          data-testid={`AdminAuthForm_oauthLoginField_${values.name || $values.type}`}
        />
        <FormCheckboxField
          label="Allow only defined logins"
          name="oauthOnlyDefinedLogins"
          data-testid={`AdminAuthForm_oauthOnlyDefinedLogins_${values.name || $values.type}`}
        />
        <FormCheckboxField
          label="Save not defined logins"
          disabled={$values.oauthOnlyDefinedLogins || !$values.oauthLoginField}
          name="oauthSaveNotDefinedLogins"
          data-testid={`AdminAuthForm_oauthSaveNotDefinedLogins_${values.name || $values.type}`}
        />
        <FormTextField
          label="OAuth group field"
          name="oauthGroupField"
          data-testid={`AdminAuthForm_oauthGroupField_${values.name || $values.type}`}
        />
        {#if $values.oauthSaveNotDefinedLogins}
          <FormTextField
            label="OAuth email field"
            name="oauthEmailField"
            data-testid={`AdminAuthForm_oauthEmailField_${values.name || $values.type}`}
          />
        {/if}
        <FormCheckboxField
          label="Allow only defined groups (searched in roles)"
          name="oauthOnlyDefinedGroups"
          data-testid={`AdminAuthForm_oauthOnlyDefinedGroups_${values.name || $values.type}`}
        />
      {/if}

      {#if authType == 'msentra'}
        <FormCheckboxField
          label="Allow only defined logins (find by email)"
          name="msentraOnlyDefinedLogins"
          data-testid={`AdminAuthForm_msentraOnlyDefinedLogins_${values.name || $values.type}`}
        />
      {/if}
    </div>
  {/if}
</FormProviderCore>

<style>
  .header {
    display: block;
    border: 1px solid var(--theme-border);
    border-radius: 5px;
    margin: var(--dim-large-form-margin);
    background-color: var(--theme-bg-2);
  }

  .title-line {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
  }

  .name {
    font-size: 1.2em;
    margin-left: 10px;
  }

  .name.disabled {
    color: var(--theme-font-3);
  }

  .name-container {
    display: flex;
    align-items: center;
  }

  .type {
    color: var(--theme-font-3);
    margin-left: 10px;
  }

  .name-type {
    display: flex;
    align-items: baseline;
  }

  .info-wrap {
    margin-left: var(--dim-large-form-margin);
  }

  .collapse-wrapper {
    margin-left: var(--dim-large-form-margin);
    border-left: 1px solid var(--theme-border);
  }
</style>
