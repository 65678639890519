<script lang="ts" context="module">
  const getCurrentEditor = () => getActiveComponent('AdminGridWithDetail');

  registerCommand({
    id: 'adminGrid.save',
    group: 'save',
    category: 'Administration',
    name: 'Save',
    toolbar: true,
    isRelatedToTab: true,
    icon: 'icon save',
    testEnabled: () => getCurrentEditor()?.canSave(),
    onClick: () => getCurrentEditor().save(),
  });

  registerCommand({
    id: 'adminGrid.revertAllChanges',
    category: 'Administration',
    name: 'Revert all changes',
    toolbarName: 'Revert all',
    icon: 'icon undo',
    testEnabled: () => getCurrentEditor()?.canSave(),
    onClick: () => getCurrentEditor().revertAllChanges(),
  });
</script>

<script lang="ts">
  import { getConnectionInfo, useConnectionInfo } from '../utility/metadataLoaders';
  import _ from 'lodash';
  import ToolStripContainer from '../buttons/ToolStripContainer.svelte';
  import ToolStripButton from '../buttons/ToolStripButton.svelte';
  import useGridConfig from '../utility/useGridConfig';
  import {
    changeSetContainsChanges,
    changeSetInsertDocuments,
    changeSetToSql,
    createChangeSet,
    createGridCache,
    CustomGridColumn,
    reloadDataCacheFunc,
    setChangeSetRowData,
  } from 'dbgate-datalib';
  import createUndoReducer from '../utility/createUndoReducer';
  import VerticalSplitter from '../elements/VerticalSplitter.svelte';
  import { writable } from 'svelte/store';
  import { extensions } from '../stores';
  import { getDatabaseInfo } from '../utility/metadataLoaders';
  import registerCommand from '../commands/registerCommand';
  import createActivator, { getActiveComponent } from '../utility/createActivator';
  import { findEngineDriver } from 'dbgate-tools';
  import { scriptToSql } from 'dbgate-sqltree';
  import { apiCall } from '../utility/api';
  import { showModal } from '../modals/modalTools';
  import ErrorMessageModal from '../modals/ErrorMessageModal.svelte';
  import { showSnackbarSuccess } from '../utility/snackbar';
  import ToolStripCommandButton from '../buttons/ToolStripCommandButton.svelte';
  import stableStringify from 'json-stable-stringify';
  import CustomDataGrid from './CustomDataGrid.svelte';
  import AdminStorageDataGrid from './AdminStorageDataGrid.svelte';
  import invalidateCommands from '../commands/invalidateCommands';
  import HorizontalSplitter from '../elements/HorizontalSplitter.svelte';
  import { markTabSaved, markTabUnsaved } from '../utility/common';
  import useEditorData from '../query/useEditorData';

  export let pureAdminTableName;
  export let selectedObjectStore;
  export let icon;
  export let newButtonTitle;
  export let getNewObject;
  export let normalizeFormValues = null;
  export let tabid;
  export let fireEvents = null;
  // export let onSave;

  export let splitHorizontal = false;

  let publishedCells = [];

  let insertedRowIndex = undefined;
  let showGridDetail = false;
  let domGrid;
  let saveKey = 0;

  export let columns: CustomGridColumn[] = [];

  const [changeSetStore, dispatchChangeSet] = createUndoReducer(createChangeSet());

  export const activator = createActivator('AdminGridWithDetail', true);
  $: connection = useConnectionInfo({ conid: '__storage' });
  $: driver = findEngineDriver($connection, $extensions);

  function saveCurrentFormValues(objectStore) {
    if (!objectStore) return;

    // console.log('objectStore', objectStore);

    const condition = objectStore.id ? { id: objectStore.id } : undefined;

    if (
      (condition != null || insertedRowIndex != null) &&
      stableStringify(objectStore) != stableStringify(publishedCells[0]?.rowData) &&
      publishedCells[0]?.rowData
    ) {
      dispatchChangeSet({
        type: 'set',
        value: setChangeSetRowData(
          $changeSetStore.value,
          {
            pureName: pureAdminTableName,
            schemaName: driver?.dialect?.defaultSchemaName,
            condition,
            insertedRowIndex,
          },
          normalizeFormValues ? normalizeFormValues(objectStore) : objectStore
        ),
      });
    }
  }

  $: {
    const cells = publishedCells;
    const rowIndexes = _.uniq(cells.map(x => x.row));
    if (rowIndexes.length == 1) {
      $selectedObjectStore = cells[0]?.rowData ?? {};
      insertedRowIndex = cells[0]?.insertedRowIndex;
      showGridDetail = true;
    } else {
      $selectedObjectStore = {};
      insertedRowIndex = undefined;
      showGridDetail = false;
    }
  }

  $: saveCurrentFormValues($selectedObjectStore);

  export async function save() {
    const resp = await apiCall('storage/save-admin-data', {
      changeSet: $changeSetStore?.value,
      fireEvents,
    });

    const { errorMessage } = resp || {};
    if (errorMessage) {
      showModal(ErrorMessageModal, {
        title: 'Error when saving',
        message: errorMessage,
      });
    } else {
      dispatchChangeSet({ type: 'reset', value: createChangeSet() });
      domGrid.reload();
      showSnackbarSuccess('Saved to database');
    }
    // await apiCall('storage/reload-anonymous-user-permissions');

    saveKey += 1;
    // if (onSave) {
    //   onSave();
    // }
  }

  export function revertAllChanges() {
    dispatchChangeSet({ type: 'reset', value: createChangeSet() });
    saveKey += 1;
  }

  export function canSave() {
    return changeSetContainsChanges($changeSetStore?.value);
  }

  // $: console.log('$changeSetStore.value', $changeSetStore.value);
  // $: console.log('driver?.dialect?.defaultSchemaName', driver?.dialect?.defaultSchemaName);
  // $: console.log('$mainConfig?.storageDatabase', $mainConfig?.storageDatabase);

  $: {
    setEditorData($changeSetStore.value);
    if (changeSetContainsChanges($changeSetStore?.value)) {
      markTabUnsaved(tabid);
    } else {
      markTabSaved(tabid);
    }
    invalidateCommands();
  }

  const { editorState, editorValue, setEditorData } = useEditorData({
    tabid,
    onInitialData: value => {
      dispatchChangeSet({ type: 'reset', value });
      invalidateCommands();
    },
  });

  $: SplitterControl = splitHorizontal ? HorizontalSplitter : VerticalSplitter;
</script>

<ToolStripContainer>
  <SplitterControl>
    <svelte:fragment slot="1">
      <AdminStorageDataGrid
        bind:this={domGrid}
        {columns}
        pureName={pureAdminTableName}
        schemaName={driver?.dialect?.defaultSchemaName}
        changeSetState={$changeSetStore}
        focusOnVisible
        {changeSetStore}
        {dispatchChangeSet}
        onPublishedCellsChanged={value => {
          publishedCells = value;
        }}
        {fireEvents}
      />
    </svelte:fragment>
    <svelte:fragment slot="2">
      {#if showGridDetail}
        <slot name="detail" changeSetState={$changeSetStore} {dispatchChangeSet} {saveKey} />
      {/if}
    </svelte:fragment>
  </SplitterControl>

  <svelte:fragment slot="toolstrip">
    <ToolStripButton
      {icon}
      on:click={() => {
        // console.log('$changeSetStore', $changeSetStore);
        dispatchChangeSet({
          type: 'set',
          value: changeSetInsertDocuments($changeSetStore.value, [getNewObject()], {
            pureName: pureAdminTableName,
            schemaName: driver?.dialect?.defaultSchemaName,
          }),
        });
      }}>{newButtonTitle}</ToolStripButton
    >
    <ToolStripCommandButton command="adminGrid.save" />
    <ToolStripCommandButton command="adminGrid.revertAllChanges" />
  </svelte:fragment>
</ToolStripContainer>
