<!-- *** This file is part of DbGate Premium ***  -->

<script lang="ts" context="module">
  import { findEngineDriver, getConnectionLabel, getEngineLabel } from 'dbgate-tools';
  import ToolStripButton from '../buttons/ToolStripButton.svelte';
  import ToolStripContainer from '../buttons/ToolStripContainer.svelte';
  import { apiCall, apiOff, apiOn } from '../utility/api';

  import { getActiveComponent } from '../utility/createActivator';
  import { useConnectionInfo, useDatabaseInfo } from '../utility/metadataLoaders';

  const getCurrentEditor = () => getActiveComponent('BackupDatabaseTab');
</script>

<script lang="ts">
  import FontIcon from '../icons/FontIcon.svelte';
  import HorizontalSplitter from '../elements/HorizontalSplitter.svelte';
  import { format as dateFormat } from 'date-fns';
  import getElectron from '../utility/getElectron';
  import WidgetColumnBar from '../widgets/WidgetColumnBar.svelte';
  import WidgetColumnBarItem from '../widgets/WidgetColumnBarItem.svelte';
  import SocketMessageView from '../query/SocketMessageView.svelte';
  import useEffect from '../utility/useEffect';
  import { copyTextToClipboard } from '../utility/clipboard';
  import WidgetTitle from '../widgets/WidgetTitle.svelte';
  import SearchBoxWrapper from '../elements/SearchBoxWrapper.svelte';
  import SearchInput from '../elements/SearchInput.svelte';
  import WidgetsInnerContainer from '../widgets/WidgetsInnerContainer.svelte';
  import AppObjectList from '../appobj/AppObjectList.svelte';
  import * as databaseObjectAppObject from '../appobj/DatabaseObjectAppObject.svelte';
  import { getObjectTypeFieldLabel } from '../utility/common';
  import _ from 'lodash';
  import { writable } from 'svelte/store';
  import FormStyledButton from '../buttons/FormStyledButton.svelte';
  import uuidv1 from 'uuid/v1';
  import { downloadFromApi } from '../utility/exportFileTools';
  import openNewTab from '../utility/openNewTab';
  import LoadingInfo from '../elements/LoadingInfo.svelte';
  import { extensions } from '../stores';
  import FormArgumentList from '../forms/FormArgumentList.svelte';
  import FormProviderCore from '../forms/FormProviderCore.svelte';

  let busy = false;

  let outputFile;
  let outputFilePath;

  let runnerId = null;
  let executeNumber = 0;

  let objectsFilter = '';
  let objectsWidth = 0;

  export let conid;
  export let database;

  const electron = getElectron();

  const connection = useConnectionInfo({ conid });

  $: driver = findEngineDriver($connection, $extensions);

  $: dbinfo = useDatabaseInfo({ conid, database });

  const checkedObjectsStore = writable([]);

  $: objectList = $dbinfo?.tables ?? [];

  $: if ($dbinfo?.tables?.length > 0) {
    checkedObjectsStore.update(x => (x?.length > 0 ? x : $dbinfo.tables));
  }

  function generateOutputFileName() {
    return `${database}-${dateFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.sql`;
  }

  async function generateOutputFilePath(file = null) {
    if (!file) file = generateOutputFileName();
    const resp = await apiCall('files/get-file-real-path', { folder: 'sql', file });
    return resp;
  }

  function getBackupParams() {
    const selectedTables = ($checkedObjectsStore ?? []).map(x => _.pick(x, ['pureName', 'schemaName']));
    const skippedTables = _.differenceBy(
      $dbinfo.tables,
      $checkedObjectsStore,
      x => `${x.schemaName}||${x.pureName}`
    ).map(x => _.pick(x, ['pureName', 'schemaName']));
    return {
      conid,
      database,
      options: $valuesStore,
      selectedTables,
      skippedTables,
    };
  }

  async function handleExecute() {
    busy = true;

    outputFile = generateOutputFileName();
    outputFilePath = await generateOutputFilePath(outputFile);

    runnerId = uuidv1();
    executeNumber += 1;

    const resp = await apiCall('database-connections/native-backup', {
      ...getBackupParams(),
      outputFile: outputFilePath,
      runid: runnerId,
    });
  }

  function handleCancel() {}

  async function handleGenerateCommand() {
    const resp = await apiCall('database-connections/native-backup-command', {
      ...getBackupParams(),
      outputFile: await generateOutputFilePath(),
    });
    copyTextToClipboard(resp.commandLine);
  }

  $: effectRunner = useEffect(() => registerRunnerDone(runnerId));

  function registerRunnerDone(rid) {
    if (rid) {
      apiOn(`runner-done-${rid}`, handleRunnerDone);
      return () => {
        apiOff(`runner-done-${rid}`, handleRunnerDone);
      };
    } else {
      return () => {};
    }
  }

  $: $effectRunner;

  const handleRunnerDone = () => {
    busy = false;
  };

  $: formArgs = (driver?.getNativeOperationFormArgs ? driver?.getNativeOperationFormArgs('backup') : null) ?? [];

  const valuesStore = writable({});
</script>

<ToolStripContainer>
  <HorizontalSplitter initialValue="50%">
    <svelte:fragment slot="1">
      <div class="content">
        <div class="source">
          <div class="labelw">
            <FontIcon icon="icon import" />
            Source:
          </div>
          <div>
            <FontIcon icon="icon server" />
            {getConnectionLabel($connection)}
            <FontIcon icon="icon database" />
            {database}
          </div>
          <div class="engine">
            {getEngineLabel($connection)}
          </div>
        </div>
        <div class="target">
          <div class="labelw">
            <FontIcon icon="icon export" />
            Target:
          </div>
          <div>
            {#if busy}
              Generating file {outputFile}
            {:else if outputFile}
              <div>{outputFile}</div>
              {#if electron}
                <FormStyledButton
                  on:click={() => {
                    electron.showItemInFolder(outputFilePath);
                  }}
                  value="Browse"
                />
                <FormStyledButton
                  on:click={async () => {
                    const file = await electron.showSaveDialog({});
                    if (file) {
                      const fs = window.require('fs');
                      fs.copyFile(outputFilePath, file, () => {});
                    }
                  }}
                  value="Save"
                />
              {:else}
                <FormStyledButton
                  on:click={() => {
                    downloadFromApi(`files/data/sql/${outputFile}`, outputFile);
                  }}
                  value="Download"
                />
              {/if}
              <FormStyledButton
                on:click={async () => {
                  const resp = await apiCall('files/load', { folder: 'sql', file: outputFile, format: 'text' });

                  const connProps = {};
                  let tooltip = undefined;

                  openNewTab(
                    {
                      title: outputFile,
                      icon: 'img sql-file',
                      tabComponent: 'QueryTab',
                      props: {
                        savedFile: outputFile,
                        savedFolder: 'sql',
                        savedFormat: 'text',
                        ...connProps,
                      },
                    },
                    { editor: resp }
                  );
                }}
                value="Open in tab"
              />
            {:else}
              SQL Files folder. Run backup to create the output file.
            {/if}
          </div>
        </div>
        <div>
          <div class="heading">Backup options</div>
          <FormProviderCore values={valuesStore}>
            <FormArgumentList args={formArgs} />
          </FormProviderCore>
        </div>
      </div>
    </svelte:fragment>

    <svelte:fragment slot="2">
      <HorizontalSplitter initialValue="50%" onChangeSize={size => (objectsWidth = size)}>
        <svelte:fragment slot="1">
          <div class="flexcol flex1">
            <WidgetTitle>Choose tables</WidgetTitle>
            <SearchBoxWrapper>
              <SearchInput placeholder="Search tables or objects" bind:value={objectsFilter} />
            </SearchBoxWrapper>

            <WidgetsInnerContainer fixedWidth={objectsWidth}>
              <AppObjectList
                list={objectList.map(x => ({ ...x, conid, database }))}
                module={databaseObjectAppObject}
                groupFunc={data => getObjectTypeFieldLabel(data.objectTypeField)}
                filter={objectsFilter}
                disableContextMenu
                {checkedObjectsStore}
                passProps={{ ingorePin: true }}
              />
            </WidgetsInnerContainer>
          </div>
        </svelte:fragment>
        <svelte:fragment slot="2">
          <WidgetColumnBar>
            <WidgetColumnBarItem title="Messages" name="messages">
              <SocketMessageView
                eventName={runnerId ? `runner-info-${runnerId}` : null}
                {executeNumber}
                showNoMessagesAlert
                showCaller
              />
            </WidgetColumnBarItem>
          </WidgetColumnBar>
        </svelte:fragment>
      </HorizontalSplitter>
    </svelte:fragment>
  </HorizontalSplitter>

  <svelte:fragment slot="toolstrip">
    {#if busy}
      <ToolStripButton icon="icon stop" on:click={handleCancel} data-testid="BackupDatabaseTab_stopButton"
        >Stop</ToolStripButton
      >
    {:else}
      <ToolStripButton on:click={handleExecute} icon="icon run" data-testid="BackupDatabaseTab_executeButton"
        >Run</ToolStripButton
      >
    {/if}
    <ToolStripButton icon="img shell" on:click={handleGenerateCommand} data-testid="BackupDatabaseTab_generateCommand"
      >Copy command line</ToolStripButton
    >
  </svelte:fragment>

  {#if busy}
    <LoadingInfo wrapper message="Exporting SQL dump" />
  {/if}
</ToolStripContainer>

<style>
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--theme-bg-0);
  }

  .source,
  .target {
    margin: 10px;
    padding: 10px;
    font-size: 15px;
    border: 1px solid var(--theme-border);
    display: flex;
  }

  .source {
    margin-bottom: 0px;
  }

  .target {
    margin-top: 0px;
  }

  .labelw {
    width: 8em;
  }

  .engine {
    color: var(--theme-font-3);
    margin-left: 10px;
  }

  .heading {
    font-size: 20px;
    margin: 10px;
  }
</style>
